import React from 'react'
import { Link } from 'gatsby'

// Styles 
import { SuccessHolder } from "./Success.styles"

const Success = () => (
    <SuccessHolder>
        <h4>Tack</h4>
        <p>Din offertförfrågan är skickad. du kommer få 5 bästa offerter.</p>
        <p>Välj du den bästa offerten.<br/>Vi önskar dig lycka till med din flytt.</p>
        <Link to={'/'}>Tillbaka till Startsidan</Link>

    </SuccessHolder>
)
export default Success