import React from "react"
import { graphql, Link } from "gatsby"
// Components
import Layout from "components/Layout"
// import Image from "components/Image"
import Image from "gatsby-image"
import SEO from "components/SEO"
import HeroTwoSides from "components/HeroTwoSides"
import IconThree from "components/IconThree"
import OffertForm from "components/OffertForm"
import Success from "components/Success"

import { HeroSides, Logo } from "components/HeroTwoSides/HeroTwoSides.styles"


  //Hooks 
import { useBilderDataQuery } from 'Hooks/useBilderQuery'
import { useSiteConfigQuery } from 'Hooks/useSiteConfigQuery'


const Page = ({ data }) => {
  const page = data.markdownRemark 
  const siteConfig = useSiteConfigQuery()
    const { 
        herobg
        
    } = useBilderDataQuery()

    const hero = herobg.childImageSharp.fluid.src;


  return (
    <Layout className="page-layout"> 

    {page.frontmatter.slug !== "success" &&  
     <>
      
      <SEO title={page.frontmatter.title} description={page.frontmatter.description}/>
      {/* <Image className="page-hero-img" fluid={page.frontmatter.image.childImageSharp.fluid} alt={page.frontmatter.titleh1}/> */}
     

      <HeroSides style={{background:'linear-gradient(to right, rgba(3,7,29,.95),rgba(9,64,110,.90)),url('+ hero +')'}}>    
        <div className="left" >
        <Logo src={siteConfig.logo.publicURL} alt="Flyttofferten" />

        </div>
        <div className="right">
            <h1 class="hero-right-subpage">Ska du<strong> flytta?</strong><br/>Fyll i formuläret så får du snabbt & smidigt kostnadsfria offerter från 
                <strong> {page.frontmatter.herotext}.</strong> </h1>
            <div className="knappar"> 
                <Link to="#offert" className="animate__animated animate__fadeIn animate__delay-1s">
                    Offert
                </Link>
                <Link to="#kontakt" className="animate__animated animate__fadeIn animate__delay-2s">
                    Kontakt
                </Link>     
            </div>
            <p className="nationell">Up till 6 gratis offerter från dina 
            lokala {page.frontmatter.herotext}. 
              {page.frontmatter.herotext === "Flyttfirma Borås" && 
                <>
                  <br/>Om du behöver 
                  hemstädning, företagsstädning, 
                  <br/><a style={{color:'white;', fontWeight:'bold;'}}
                  href="https://www.stadfirmorboras.se">besök 
                  Städfirmor Borås.</a>
                </>
              }
              </p>
        </div>
    </HeroSides>



     
      
      <IconThree />
      <OffertForm />
      </>}

         {page.frontmatter.slug === "success" && 
          <>
           <Success />      
         </> } 
    </Layout>
  )
}

export default Page

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html 
      frontmatter { 
        stad
        herotext
        slug
        title 
        description
           
      }
    }
  }
`
